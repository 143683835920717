var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring"
  }, [_c('BCardActions', {
    staticClass: "mt-1",
    attrs: {
      "title": "Realtime Monitoring - River Route",
      "action-collapse": "",
      "collapsed": false
    }
  }, [_c('b-row', {
    staticClass: "yo-row",
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-dropdown', {
    staticClass: "yo-dropdown",
    attrs: {
      "id": "status",
      "variant": _vm.dropdownStatusVariant(_vm.b_dropdown || 'KAPAL SANDAR')
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          domProps: {
            "textContent": _vm._s(_vm.b_dropdown || 'KAPAL SANDAR')
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-header', {
    attrs: {
      "id": "dropdown-header-label"
    }
  }, [_c('small', {
    staticClass: "text-secondary"
  }, [_c('strong', [_vm._v("Ubah Data Filter (Status) By " + _vm._s(_vm.moment().format('D MMMM YYYY')) + " ( Today )")])])]), _c('b-dropdown-item', {
    staticClass: "d-block",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchData(true, 'KAPAL BERANGKAT');
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'success'
    }
  }, [_vm._v(" KAPAL BERANGKAT ")])], 1), _c('b-dropdown-item', {
    staticClass: "d-block",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchData(true, 'KAPAL SANDAR');
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'warning'
    }
  }, [_vm._v(" KAPAL SANDAR ")])], 1), _c('b-dropdown-item', {
    staticClass: "d-block",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchData(true, 'KAPAL RENCANA SANDAR');
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v(" KAPAL RENCANA SANDAR ")])], 1), _c('b-dropdown-item', {
    staticClass: "d-block",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchData(true, 'CANCEL');
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'danger'
    }
  }, [_vm._v(" KAPAL (CANCEL/KEGIATAN DIBATALKAN) ")])], 1)], 1), _vm.b_dropdown === 'TAMPILKAN SEMUA DATA' ? _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" By Time "), _c('strong', [_vm._v("Rencana Sandar ")]), _vm._v(" : " + _vm._s(_vm.moment().format('D MMMM YYYY')) + " ( "), _c('strong', [_vm._v("Default / Today")]), _vm._v(" ) ")]) : _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" By Time "), _c('strong', [_vm._v(_vm._s(_vm.b_dropdown) + " ")]), _vm._v(" : " + _vm._s(_vm.moment().format('D MMMM YYYY')) + " ( "), _c('strong', [_vm._v("Default / Today")]), _vm._v(" ) ")])], 1), _c('b-col', {
    staticClass: "text-right d-none d-sm-block",
    attrs: {
      "cols": "3"
    }
  }, [_c('div', {
    staticClass: "custom-search d-flex justify-content-end"
  }, [_c('b-form-group', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mr-1"
  }, [_vm._v("Search")]), _c('b-form-input', {
    staticClass: "d-inline-block",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1)])], 1)])], 1), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _c('vue-good-table', {
    key: _vm.yoRender,
    staticClass: "yo-good-table",
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "rtl": _vm.direction,
      "search-options": {
        enabled: true,
        externalQuery: _vm.searchTerm
      },
      "select-options": {
        enabled: true,
        selectOnCheckboxOnly: true,
        // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        // disable the select info panel on top
        selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      "pagination-options": {
        enabled: false,
        perPage: _vm.row_option.size
      },
      "group-options": {
        enabled: true
      },
      "compact-mode": "",
      "theme": "polar-bear",
      "style-class": "vgt-table bordered",
      "max-height": "700px",
      "line-numbers": true,
      "fixed-header": true
    },
    scopedSlots: _vm._u([{
      key: "table-header-row",
      fn: function fn(props) {
        return [_c('BBadge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('strong', [_vm._v(_vm._s(props.row.label))])]), _c('BBadge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v(" Jumlah Data : " + _vm._s(props.row.children.length) + " ")])];
      }
    }, {
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'form_status_main' ? _c('span', [_c('b-badge', {
          staticClass: "d-block",
          attrs: {
            "variant": _vm.statusVariant(props.row.form_status_main)
          }
        }, [_vm._v(" " + _vm._s(props.row.form_status_main) + " ")]), _c('strong', [_c('small', [_vm._v(_vm._s(props.row.form_status))])]), _c('br'), _c('strong', [_c('small', {
          staticClass: "text-secondary"
        }, [_vm._v(_vm._s(_vm.customValueDate(props.row)))]), _c('feather-icon', {
          staticClass: "cursor-pointer ml-1",
          attrs: {
            "icon": _vm.customValueDate(props.rows).includes('AM') ? 'SunIcon' : 'MoonIcon'
          }
        })], 1)], 1) : props.column.field === 'no_doc' ? _c('span', [_vm._v(" " + _vm._s(props.row.no_doc) + " "), _c('b-button', {
          staticClass: "btn-icon mt-1",
          attrs: {
            "variant": "primary",
            "to": {
              name: _vm.router(props.row.from),
              params: {
                id: props.row.form_id,
                api: _vm.api(props.row.from)
              }
            },
            "block": "",
            "disabled": _vm.actionDisable(props.row.user_id, props.row.from, props.row.pemilik)
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer mr-1",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v(_vm._s(_vm.actionDisable(props.row.user_id, props.row.from, props.row.pemilik) ? 'No Access' : 'Check Data'))])], 1)], 1) : props.column.field === 'rencana_sandar' ? _c('span', [_vm._v(" " + _vm._s(_vm.dateHumanReadAble(props.row.rencana_sandar)) + " "), _c('feather-icon', {
          staticClass: "cursor-pointer mr-1",
          attrs: {
            "icon": _vm.dateHumanReadAble(props.row.rencana_sandar).includes('AM') ? 'SunIcon' : 'MoonIcon'
          }
        })], 1) : props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "AnchorIcon"
          }
        }), _c('span', [_vm._v("Detail Data")])], 1)], 1)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }