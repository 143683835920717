<template>
  <div class="monitoring">
    <BCardActions
      title="Realtime Monitoring - River Route"
      action-collapse
      class="mt-1"
      :collapsed="false"
    >
      <b-row class="yo-row" align-h="between" align-v="center">
        <b-col cols="9">
          <b-dropdown
            id="status"
            :variant="dropdownStatusVariant(b_dropdown || 'KAPAL SANDAR')"
            class="yo-dropdown"
          >
            <template #button-content>
              <span v-text="b_dropdown || 'KAPAL SANDAR'" />
            </template>
            <b-dropdown-header id="dropdown-header-label">
              <small class="text-secondary">
                <strong
                  >Ubah Data Filter (Status) By
                  {{ moment().format('D MMMM YYYY') }} ( Today )</strong
                >
              </small>
            </b-dropdown-header>
            <b-dropdown-item
              class="d-block"
              variant="success"
              @click="fetchData(true, 'KAPAL BERANGKAT')"
            >
              <b-badge :variant="'success'"> KAPAL BERANGKAT </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              class="d-block"
              variant="warning"
              @click="fetchData(true, 'KAPAL SANDAR')"
            >
              <b-badge :variant="'warning'"> KAPAL SANDAR </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              class="d-block"
              variant="info"
              @click="fetchData(true, 'KAPAL RENCANA SANDAR')"
            >
              <b-badge :variant="'info'"> KAPAL RENCANA SANDAR </b-badge>
            </b-dropdown-item>
            <b-dropdown-item
              class="d-block"
              variant="danger"
              @click="fetchData(true, 'CANCEL')"
            >
              <b-badge :variant="'danger'">
                KAPAL (CANCEL/KEGIATAN DIBATALKAN)
              </b-badge>
            </b-dropdown-item>
            <!-- <b-dropdown-item
              class="d-block"
              variant="primary"
              @click="fetchData(true, 'all')"
            >
              <b-badge
                :variant="'primary'"
              >
                TAMPILKAN SEMUA DATA
              </b-badge>
            </b-dropdown-item> -->
          </b-dropdown>
          <span v-if="b_dropdown === 'TAMPILKAN SEMUA DATA'" class="ml-2">
            By Time <strong>Rencana Sandar </strong> :
            {{ moment().format('D MMMM YYYY') }} (
            <strong>Default / Today</strong> )
          </span>
          <span v-else class="ml-2">
            By Time <strong>{{ b_dropdown }} </strong> :
            {{ moment().format('D MMMM YYYY') }} (
            <strong>Default / Today</strong> )
          </span>
        </b-col>
        <b-col class="text-right d-none d-sm-block" cols="3">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <div v-if="yo_isLoading" class="progress-wrapper">
        <b-card-text class="mb-0">
          Memuat data… {{ yo_isLoadingValue + '%' }}
        </b-card-text>
        <b-progress
          :key="yo_isLoadingValue"
          animated
          :value="yo_isLoadingValue"
          variant="info"
          max="100"
          :class="'progress-bar-info'"
        />
      </div>
      <!-- table -->
      <vue-good-table
        :key="yoRender"
        class="yo-good-table"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: false,
          perPage: row_option.size
        }"
        :group-options="{
          enabled: true
        }"
        compact-mode
        theme="polar-bear"
        style-class="vgt-table bordered"
        max-height="700px"
        :line-numbers="true"
        :fixed-header="true"
      >
        <!--
         -->
        <template slot="table-header-row" slot-scope="props">
          <BBadge variant="primary" class="mr-1">
            <strong>{{ props.row.label }}</strong>
          </BBadge>
          <BBadge variant="secondary">
            Jumlah Data : {{ props.row.children.length }}
          </BBadge>
        </template>
        <template slot="table-row" slot-scope="props">
          <!-- Column: Status -->
          <span v-if="props.column.field === 'form_status_main'">
            <b-badge
              :variant="statusVariant(props.row.form_status_main)"
              class="d-block"
            >
              {{ props.row.form_status_main }}
            </b-badge>
            <strong>
              <small>{{ props.row.form_status }}</small>
            </strong>
            <br />
            <strong>
              <small class="text-secondary">{{
                customValueDate(props.row)
              }}</small>
              <feather-icon
                class="cursor-pointer ml-1"
                :icon="
                  customValueDate(props.rows).includes('AM')
                    ? 'SunIcon'
                    : 'MoonIcon'
                "
              />
            </strong>
          </span>
          <span v-else-if="props.column.field === 'no_doc'">
            {{ props.row.no_doc }}
            <b-button
              variant="primary"
              :to="{
                name: router(props.row.from),
                params: { id: props.row.form_id, api: api(props.row.from) }
              }"
              class="btn-icon mt-1"
              block
              :disabled="
                actionDisable(
                  props.row.user_id,
                  props.row.from,
                  props.row.pemilik
                )
              "
            >
              <feather-icon class="cursor-pointer mr-1" icon="EyeIcon" />
              <span>{{
                actionDisable(
                  props.row.user_id,
                  props.row.from,
                  props.row.pemilik
                )
                  ? 'No Access'
                  : 'Check Data'
              }}</span>
              <!-- <feather-icon
                class="cursor-pointer"
                icon="AnchorIcon"
              /> -->
            </b-button>
          </span>
          <span v-else-if="props.column.field === 'rencana_sandar'">
            {{ dateHumanReadAble(props.row.rencana_sandar) }}
            <feather-icon
              class="cursor-pointer mr-1"
              :icon="
                dateHumanReadAble(props.row.rencana_sandar).includes('AM')
                  ? 'SunIcon'
                  : 'MoonIcon'
              "
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon icon="AnchorIcon" class="mr-50" />
                  <span>Detail Data</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item>
                  class="d-block"
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Bata</span>
                </b-dropdown-item> -->
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <!-- <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="row_option.size"
                :options="row_option.size_option"
                class="mx-1"
              />
              <span class="text-nowrap"> of {{ row_option.total_items }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="row_option.current_page"
                :total-rows="row_option.total_items"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                :per-page="row_option.size"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> -->
      </vue-good-table>
    </BCardActions>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
    BCardActions
  },
  setup() {
    const { isVerticalMenuCollapsed, anIncomingNotification } = useAppConfig()

    // const isCollapse = computed(() => isCollapse.value === true)
    // const triggerFCM = computed(() => triggerFCM.value >= 1)

    return {
      isVerticalMenuCollapsed /* Fixed Header !false Good Table #issue debounce 300s */,
      // isCollapse,
      anIncomingNotification /* FirebaseFCM */
    }
  },
  data() {
    return {
      yoRender: 1,
      yoTimeoutDebounce: null,
      yoUserData: null,
      yo_isLoading: false,
      yo_isLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dir: false,
      columns: [
        {
          label: 'Status',
          field: 'form_status_main',
          filterOptions: {
            enabled: false,
            placeholder: 'Cari Status'
          }
        },
        {
          label: 'No Dokumen',
          field: 'no_doc',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari No Dokumen'
          }
        },
        {
          label: 'Nomor PKK',
          field: 'no_pkk',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari No PKK'
          }
        },
        {
          label: 'Nama Kapal',
          field: 'boat_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Kapal'
          }
        },
        {
          label: 'Kegiatan',
          field: 'kegiatan',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Kegiatan'
          }
        },
        {
          label: 'Posisi',
          field: 'posisi_jetty',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Posisi Sekarang'
          }
        },
        {
          label: 'Pemilik',
          field: 'pemilik',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Pemilik'
          }
        },
        {
          label: 'Rencana Sandar',
          field: 'rencana_sandar',
          filterOptions: {
            enabled: true,
            placeholder: 'Cari Rencana Sandar'
          }
        }
      ],
      rows: [],
      b_dropdown: null,
      row_option: {
        form_status_main: 'KAPAL SANDAR',
        size: 9999, // page length
        size_option: ['5', '10', '15', '20', '100'], // page option
        current_page: 1, // as page
        total_items: 20,
        total_pages: 3
      },
      searchTerm: ''
    }
  },
  computed: {
    dropdownStatusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'TAMPILKAN SEMUA DATA': 'primary',
        'KAPAL BERANGKAT': 'success',
        'KAPAL (CANCEL/KEGIATAN DIBATALKAN)': 'danger',
        'KAPAL SANDAR': 'warning',
        'KAPAL RENCANA SANDAR': 'info'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        'KAPAL BERANGKAT': 'light-success',
        CANCEL: 'light-danger',
        'KAPAL SANDAR': 'light-warning',
        'KAPAL RENCANA SANDAR': 'light-info'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {
    isVerticalMenuCollapsed() {
      console.log('collapse', this.isVerticalMenuCollapsed)
      if (this.yoTimeoutDebounce) clearTimeout(this.yoTimeoutDebounce)
      this.yoTimeoutDebounce = setTimeout(() => {
        this.yoRender += 1
      }, 300)
    },
    anIncomingNotification() {
      console.log(
        'RMSungai.vue : an incoming mesage key : ',
        this.anIncomingNotification
      )
    },
    'document.body.style.zoom': function () {
      console.log('screen change')
      this.yoRender += 1
    }
  },
  mounted() {
    document.body.style.zoom = '70%'
    // document.getElementById('app').style.MozTransform = 'scale(1)'
    // document.getElementById('app').style.MozTransformOrigin = '0 0'
  },
  destroyed() {
    document.body.style.zoom = '100%'
    // document.getElementById('app').style.MozTransform = 'scale(1)'
    // document.getElementById('app').style.MozTransformOrigin = '0 0'
  },
  created() {
    const yoUserData = getUserData()
    this.yoUserData = yoUserData
    this.isTimerStart()
    const query = {
      page: this.row_option.current_page - 1,
      size: 100
    }
    useJwt.http
      .get('vw_monitor_union', { params: { ...query } })
      .then((res) => {
        this.b_dropdown = 'TAMPILKAN SEMUA DATA'
        const { vw_monitor_unions, total_pages, total_items, current_page } =
          res.data
        // eslint-disable-next-line camelcase
        this.rows = vw_monitor_unions
        this.row_option = {
          ...this.row_option,
          current_page,
          total_items,
          total_pages
        }
        this.isTimerDone()
      })
  },
  beforeDestroy() {
    this.isTimerDone()
  },
  methods: {
    moment,
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.yo_isLoading = false
    },
    isTimerStart() {
      this.yo_isLoading = true
      this.yoTimer = setInterval(() => {
        // eslint-disable-next-line
        if (this.yo_isLoadingValue <= 75 && this.yo_isLoading >= 0) {
          this.yo_isLoadingValue += 25
        } else {
          this.yo_isLoadingValue = 0
        }
      }, 1000)
    },
    actionDisable(userID, isTBBM, pemilik) {
      if (this.$can('manage', 'all')) {
        return false
      }
      if (this.$can('SAL', '') || this.$can('manage', 'special')) {
        if (isTBBM === 'form_tbbm') {
          if (this.yoUserData.group.name === pemilik) {
            return false
          }
          if (this.$can('SAL', '')) {
            return false
          }
          return true
        }
        if (isTBBM === 'form_hulu_migas') {
          if (['TEM', 'PATRA'].includes(this.yoUserData.group.name)) {
            return true
          }
          if (this.$can('SAL', '')) {
            return false
          }
          return true
        }
        return false
      }
      if (this.$can('TBBM', '') || this.$can('Hulu Migas', '')) {
        if (this.yoUserData.id === userID) {
          // console.log('by user:', this.yoUserData.id)
          return false
        }
        // console.log('by user to disabled: ', this.yoUserData.id)
        return true
      }
      // console.log('nothing: ', this.yoUserData.id)
      return true
    },
    router(value) {
      let string
      if (value === 'form_tbbm') {
        string = 'dashboard-monitoring-rrv-tbbm-view'
      } else {
        string = 'dashboard-monitoring-rrv-hulu-migas-view'
      }
      return string
    },
    api(value) {
      let string
      if (value === 'form_tbbm') {
        string = 'form_tbbm'
      } else {
        string = 'form_hulu_migas'
      }
      return string
    },
    dateHumanReadAble(value) {
      return moment(value).format('D MMMM - hh:mm A')
    },
    customValueDate(value) {
      if (value) {
        let string = value.rencana_sandar
        if (value.sandar_start_time) {
          string = value.sandar_start_time
          // console.log('1')
        }
        if (value.kegiatan_start_time) {
          string = value.kegiatan_start_time
          // console.log('2')
        }
        if (value.kegiatan_end_time) {
          string = value.kegiatan_end_time
          // console.log('3')
        }
        if (value.sandar_end_time) {
          string = value.sandar_end_time
          // console.log('4', value.sandar_end_time)
        }
        if (value.kegiatan_batal_time) {
          string = value.kegiatan_batal_time
          // console.log('5')
        }
        return moment(string).format('D MMMM - hh:mm A')
      }

      return 'am'
    },
    fetchData(state, keyword) {
      this.isTimerStart()
      this.b_dropdown = keyword
      let query = {
        page: 0,
        size: 20
      } /* default */
      if (keyword === 'all') {
        query = {
          ...query
        }
        this.b_dropdown = 'TAMPILKAN SEMUA DATA'
      } else {
        query = {
          ...query,
          keyword
        }
      }
      if (keyword === 'CANCEL') {
        this.b_dropdown = 'KAPAL (CANCEL/KEGIATAN DIBATALKAN)'
      }
      useJwt.http
        .get('vw_monitor_union', { params: { ...query } })
        .then((res) => {
          const { vw_monitor_unions, total_pages, total_items, current_page } =
            res.data
          // eslint-disable-next-line camelcase
          this.rows = vw_monitor_unions
          this.row_option = {
            ...this.row_option,
            current_page,
            total_items,
            total_pages
          }
          this.isTimerDone()
        })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style lang="scss" scoped>
$color-yo-border: 5px solid #2f2f2f1b;
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        .monitoring {
          .yo-good-table::v-deep .vgt-table {
            border: $color-yo-border !important;
            width: 100% !important;
            thead {
              th {
                border: $color-yo-border !important;
              } /* header custom */
              .filter-th {
                input {
                  border: $color-yo-border !important;
                  font-weight: bolder;
                }
              }
            }
            tbody {
              th {
                border: $color-yo-border !important;
              } /* left checkbox */
              td {
                border: $color-yo-border !important;
              } /* right checkbox */
            }
          }
          .yo-row {
            .yo-dropdown >>> .dropdown-menu {
              z-index: 999;
            }
            .yo-dropdown::v-deep .dropdown-menu {
              z-index: 999;
            }
          }
        }
      }
    }
  }
}
</style>
